import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PaymentCallbackGuard } from './guards/payment-callback.guard';
import { PaymentComponent } from './components/payment/payment.component';

const routes: Routes = [
    {
        path: 'tranzila-payment/callback',
        component: PaymentComponent,
        canActivate: [PaymentCallbackGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class TranzilaRoutingModule {}
