import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Plan } from '../models/plan.model';
import { Observable, of } from 'rxjs';

@Injectable()
export class PlanService {
    private collectionName = 'plans';

    readonly plans: Plan[] = [
        {
            price: 300,
            title: 'איתור בסיסי',
            features: [
                { title: 'איתור בסיסי לפוליסות וקופות גמל', included: true },
                { title: 'סיכום תיק', included: true },
                { title: 'איתור מורחב של פוליסות וקופות גמל', included: false },
                { included: false, title: 'בדיקת עלויות' },
                { included: false, title: 'סיכום תיק. תכנון פנסיוני, חלוקת כספים (הוני וקצבה), ציפיות לפנסיה' },
                { included: false, title: 'ביצוע בדיקת הפקדות הוסטורית' },
                { title: 'איתור אי התאמות בין ההפקשות לקופות השונות לשכר', included: false },
                { title: 'בקשות שינוי וסיכום הפרשים /הפסד תשואות היסטוריות', included: false },
                { title: 'בדיקות מיסוי', included: false },
                { title: 'חישובים ותכנוני פרישה לפי מודלי הפרישה המתקדמים', included: false }
            ],
            id: 'd35Xr6e7nC1fuu5256Us'
        },
        {
            price: 1000,
            title: 'איתור וריכוז',
            features: [
                { title: 'איתור בסיסי לפוליסות וקופות גמל', included: false },
                { title: 'סיכום תיק', included: true },
                { title: 'איתור מורחב של פוליסות וקופות גמל', included: true },
                { included: true, title: 'בדיקת עלויות' },
                { included: true, title: 'סיכום תיק. תכנון פנסיוני, חלוקת כספים (הוני וקצבה), ציפיות לפנסיה' },
                { included: false, title: 'ביצוע בדיקת הפקדות הוסטורית' },
                { title: 'איתור אי התאמות בין ההפקשות לקופות השונות לשכר', included: false },
                { title: 'בקשות שינוי וסיכום הפרשים /הפסד תשואות היסטוריות', included: false },
                { title: 'בדיקות מיסוי', included: false },
                { title: 'חישובים ותכנוני פרישה לפי מודלי הפרישה המתקדמים', included: false }
            ],
            id: '2'
        },
        {
            price: 2000,
            title: 'בדיקת היסטורית',
            features: [
                { title: 'איתור בסיסי לפוליסות וקופות גמל', included: false },
                { title: 'סיכום תיק', included: true },
                { title: 'איתור מורחב של פוליסות וקופות גמל', included: true },
                { included: true, title: 'בדיקת עלויות' },
                { included: true, title: 'סיכום תיק. תכנון פנסיוני, חלוקת כספים (הוני וקצבה), ציפיות לפנסיה' },
                { included: true, title: 'ביצוע בדיקת הפקדות הוסטורית' },
                { title: 'איתור אי התאמות בין ההפקשות לקופות השונות לשכר', included: true },
                { title: 'בקשות שינוי וסיכום הפרשים /הפסד תשואות היסטוריות', included: true },
                { title: 'בדיקות מיסוי', included: false },
                { title: 'חישובים ותכנוני פרישה לפי מודלי הפרישה המתקדמים', included: false }
            ],
            id: '3'
        },
        {
            price: 4000,
            title: 'תכנון פרישה',
            features: [
                { title: 'איתור בסיסי לפוליסות וקופות גמל', included: false },
                { title: 'סיכום תיק', included: true },
                { title: 'איתור מורחב של פוליסות וקופות גמל', included: true },
                { included: true, title: 'בדיקת עלויות' },
                { included: true, title: 'סיכום תיק. תכנון פנסיוני, חלוקת כספים (הוני וקצבה), ציפיות לפנסיה' },
                { included: false, title: 'ביצוע בדיקת הפקדות הוסטורית' },
                { title: 'איתור אי התאמות בין ההפקשות לקופות השונות לשכר', included: false },
                { title: 'בקשות שינוי וסיכום הפרשים /הפסד תשואות היסטוריות', included: false },
                { title: 'בדיקות מיסוי', included: true },
                { title: 'חישובים ותכנוני פרישה לפי מודלי הפרישה המתקדמים', included: true }
            ],
            id: '3'
        }
    ];

    constructor(private db: AngularFirestore) {
    }

    public list(): Observable<Array<Plan>> {
        return of(this.plans);

        // return this.db.collection<Plan>(this.collectionName).snapshotChanges().pipe(
        //     map((plans: any) => plans.map(plan => ({
        //         ...plan.payload.doc.data(),
        //         id: plan.payload.doc.id
        //     }))),
        //     take(1),
        // );
    }
}
