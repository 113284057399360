import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class PaymentCallbackGuard implements CanActivate {
    constructor(@Inject('WINDOW') private win: any) {

    }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const routeParams = route.queryParams;

        const messageData = {
            source: 'tranzila-payment',
            success: true,
            sum: routeParams.sum,
            confirmationCode: routeParams.ConfirmationCode
        };

        if (routeParams.pdesc) {
            routeParams.pdesc.split(',').forEach(param => messageData[ param.split(':')[ 0 ] ] = param.split(':')[ 1 ]);
        }

        this.win.parent.postMessage(messageData, '*');

        return false;
    }

}
