import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-tranzila-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent implements OnInit, OnDestroy {
    private readonly validationCode = Math.random().toString(36).substring(7);

    readonly serviceEmail = 'service@forbit.co.il';

    public paymentStatusSuccess: boolean;

    @Input() private terminalName: string;
    @Input() private name: string;
    @Input() private email: string;
    @Input() private price: number;

    @Output() private paymentFail    = new EventEmitter();
    @Output() private paymentSuccess = new EventEmitter<any>();

    private onDestroy$ = new Subject<boolean>();

    constructor(private domSanitizer: DomSanitizer, @Inject('WINDOW') private win: any) {
    }

    public ngOnInit(): void {
        fromEvent(this.win, 'message').pipe(
            takeUntil(this.onDestroy$),
            filter((message: MessageEvent) => message.data && message.data.source === 'tranzila-payment'),
            tap((message: MessageEvent) => this.handleCallBack(message.data))
        ).subscribe();
    }

    private handleCallBack(data: any) {
        if (data.success) {
            this.paymentStatusSuccess = true;
            this.paymentSuccess.emit(data.confirmationCode);
        } else {
            this.paymentFail.emit();
        }
    }

    private get tranzilaDirectLink() {
        return `https://app.icount.co.il/m/${this.terminalName}`;
    }

    getValidationObj() {
        return {
            validationCode: this.validationCode,
            orderId: this.email
        };
    }

    public get iframeSrc(): SafeResourceUrl {
        let url = `${this.tranzilaDirectLink}?cs=${this.price}`;
        url += `&full_name=${this.name}`;
        url += `&contact_email=${this.email ? this.email : this.serviceEmail}`;
        url += `&pdesc=${JSON.stringify(this.getValidationObj())}`;
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next(true);
    }
}
