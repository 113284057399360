import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Plan } from '../models/plan.model';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';

@Injectable()
export class PurchaseService {
    private collectionName = 'purchases';

    constructor(private db: AngularFirestore) {
    }

    public createPurchase(customerId: string, plan: Plan, token: string): Observable<string> {
        const payload = {
            customerId,
            planId: plan.id,
            planName: plan.title,
            price: plan.price,
            token
        };

        return fromPromise(this.db.collection(this.collectionName).add(payload)).pipe(
            map((response: any) => response.id)
        );
    }
}
