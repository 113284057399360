import { Injectable } from '@angular/core';
import { PersonalDetails } from '../models/personal-details.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerService {
    private collectionName = 'customers';

    constructor(private db: AngularFirestore) {
    }

    public createCustomer(personalDetails: PersonalDetails): Observable<string> {
        const payload = {
            ...personalDetails,
            createdOn: (new Date()).getTime()
        };

        delete payload.files;

        return fromPromise(this.db.collection(this.collectionName).add(payload)).pipe(
            map((response: any) => response.id)
        );
    }
}
