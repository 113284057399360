import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { forkJoin, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class StorageService {
    private collectionName = 'files';

    constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
    }

    public uploadFiles(customerId: string, files: Array<File>): Observable<any> {
        const fileObservables: Array<Observable<any>> = [];

        if (files && files.length) {
            files.filter((file: any) => file instanceof File)
            .forEach((file: File) => fileObservables.push(this.uploadFile(customerId, file)));

            if (fileObservables.length) {
                return forkJoin(fileObservables);
            }
        }

        return of({});
    }

    public uploadFile(customerId: string, file: File): Observable<string> {
        const filePath = `customers/${customerId}/${file.name}`;

        return fromPromise(this.storage.upload(filePath, file)).pipe(
            exhaustMap((fileUploadResponse: any) => {
                const payload = {
                    customerId,
                    name: file.name,
                    path: filePath,
                    createdOn: (new Date()).getTime()
                };

                return fromPromise(this.db.collection(this.collectionName).add(payload)).pipe(
                    map((response: any) => response.id)
                );
            })
        );
    }
}
