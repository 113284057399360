export const environment = {
    production: true,
    fireBase: {
        apiKey: 'AIzaSyA9Ba2u-mELAbrVo-_woRQBsO6VcEgLqdE',
        authDomain: 'forbit-c7277.firebaseapp.com',
        databaseURL: 'https://forbit-c7277.firebaseio.com',
        projectId: 'forbit-c7277',
        storageBucket: 'forbit-c7277.appspot.com',
        messagingSenderId: '37537382807',
        appId: '1:37537382807:web:a0a62cc3ea466fce'
    }
};
