import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './components/payment/payment.component';
import { TranzilaRoutingModule } from './tranzila-routing.module';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './services/custom-url-serializer';
import { PaymentCallbackGuard } from './guards/payment-callback.guard';
import { TranslateModule } from '@ngx-translate/core';

const win = window;

@NgModule({
    declarations: [
        PaymentComponent
    ],
    imports: [
        CommonModule,
        TranzilaRoutingModule,
        TranslateModule
    ],
    providers: [
        { provide: 'WINDOW', useValue: win },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        PaymentCallbackGuard
    ],
    exports: [
        PaymentComponent,
        TranzilaRoutingModule
    ]
})
export class TranzilaModule {}
